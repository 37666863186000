<template>
  <v-card rounded="lg" class="px-9 pt-8 pb-7">
    <div class="font-weight-bold text-h4 mb-5">
      {{ eventName }}
    </div>
    <div class="text-h4">
      <div class="mb-3">
        {{ eventClient }}
      </div>
      <div class="mb-3">
        {{ convertUnixToHourMinute(eventStartTime) }} -
        {{ convertUnixToHourMinute(eventEndTime) }}
      </div>
      <div v-if="productType" class="mb-3">
        {{ productType }}
      </div>
      <div
        v-if="!isExternalEvent && productType === 'Paquete'"
        class="d-flex justify-end"
      >
        <Button
          text="Ver reservación"
          outlined
          aria-label="Redirigir a la página de reservación"
          url="ProviderPackageReservation"
          :url-params="{ id: reservationId }"
        />
      </div>
    </div>
  </v-card>
</template>

<script>
import Button from "@/components/Shared/Button.vue";
import { convertUnixToHourMinute } from "@/Utils/dateConverter";

export default {
  name: "CalendarEventCard",
  components: {
    Button,
  },
  props: {
    eventName: { type: String, required: true },
    eventClient: { type: String, required: true },
    eventStartTime: { type: String, required: true },
    eventEndTime: { type: String, required: true },
    isExternalEvent: { type: Boolean, default: false },
    productType: { type: String, default: null },
    reservationId: { type: Number, default: null },
  },
  methods: {
    convertUnixToHourMinute(unixDate) {
      return convertUnixToHourMinute(parseInt(unixDate));
    },
  },
};
</script>
