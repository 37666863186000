<template>
  <div>
    <div class="d-flex justify-space-between">
      <a class="py-3 px-4" @click="displayPreviousMonth()">
        <v-img :src="ChevronLeftIcon" />
      </a>
      <a class="py-3 px-4" @click="displayNextMonth()">
        <v-img :src="ChevronRightIcon" />
      </a>
    </div>
    <v-row
      v-for="(week, i) in weeks"
      :key="i"
      class="flex-nowrap mt-4"
      no-gutters
    >
      <v-col
        v-for="(dayOfWeek, j) in week"
        :key="j"
        class="d-flex flex-column align-center mb-2 mb-md-5 mr-1 mr-lg-5"
      >
        <span v-if="i === 0" class="text-h4 text-sm-h3 font-weight-bold mb-12">
          {{ daysOfWeekHeaders[j] }}
        </span>
        <v-sheet
          :height="
            $vuetify.breakpoint.xs ? 43 : $vuetify.breakpoint.sm ? 70 : 100
          "
          :width="
            $vuetify.breakpoint.xs ? 43 : $vuetify.breakpoint.sm ? 70 : 100
          "
          class="rounded-circle d-flex justify-center align-center"
          :class="[
            dayOfWeek.day && 'cursor-pointer',
            dayOfWeek.day === selectedDay && 'selected-day event-day',
            dayOfWeek.hasEvent && 'event-day',
          ]"
          @click="dayOfWeek.day && displayEvents(dayOfWeek.day)"
        >
          <span
            class="text-boby-1 text-sm-h6"
            :class="dayOfWeek.day === selectedDay && 'font-weight-bold'"
          >
            {{ dayOfWeek.day }}
          </span>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ChevronLeftIcon from "@/assets/icons/providers/ChevronLeft.svg";
import ChevronRightIcon from "@/assets/icons/providers/ChevronRight.svg";

export default {
  name: "MonthCalendar",
  props: {
    selectedMonth: { type: Number, required: true },
    selectedYear: { type: Number, required: true },
    selectedDay: { type: Number, required: true },
    calendarEvents: { type: Array, required: true },
  },
  emits: ["displayPreviousMonth", "displayNextMonth", "displayEvents"],
  data() {
    return {
      ChevronLeftIcon: ChevronLeftIcon,
      ChevronRightIcon: ChevronRightIcon,
      daysOfWeekHeaders: ["DOM", "LUN", "MAR", "MIÉ", "JUE", "VIE", "SÁB"],
      weeks: [],
    };
  },
  watch: {
    selectedDay() {
      this.generateDays();
    },
    selectedMonth() {
      this.generateDays();
    },
    selectedYear() {
      this.generateDays();
    },
  },
  created() {
    this.generateDays();
  },
  methods: {
    displayEvents(day) {
      this.$emit("displayEvents", {
        day,
        month: this.selectedMonth,
        year: this.selectedYear,
      });
    },
    generateDays() {
      this.weeks = [];

      const year = this.selectedYear;
      const month = this.selectedMonth;

      const daysInMonth = new Date(year, month, 0).getDate();
      const firstDayOfMonth = new Date(year, month - 1, 1).getDay();
      const lastDayOfMonth = new Date(year, month - 1, daysInMonth).getDay();

      const startBlankDays = Array.from({ length: firstDayOfMonth }, () => ({
        day: "",
        hasEvent: false,
      }));

      const days = Array.from({ length: daysInMonth }, (_, i) => {
        const startDate = new Date(year, month - 1, i + 1);
        const endDate = new Date(year, month - 1, i + 2);

        const startTimestamp = Math.floor(startDate.getTime() / 1000);
        const endTimestamp = Math.floor(endDate.getTime() / 1000);

        const hasEvent = this.calendarEvents.some(
          (event) =>
            (startTimestamp >= parseInt(event.startTime) &&
              endTimestamp < parseInt(event.startTime)) ||
            (startTimestamp >= parseInt(event.endTime) &&
              endTimestamp < parseInt(event.endTime)) ||
            (startTimestamp <= parseInt(event.startTime) &&
              endTimestamp >= parseInt(event.endTime))
        );

        return {
          day: i + 1,
          hasEvent: hasEvent,
        };
      });

      const endBlankDays = Array.from({ length: 6 - lastDayOfMonth }, () => ({
        day: "",
        hasEvent: false,
      }));

      const allDays = startBlankDays.concat(days, endBlankDays);

      while (allDays.length) {
        this.weeks.push(allDays.splice(0, 7));
      }
    },
    displayPreviousMonth() {
      this.$emit("displayPreviousMonth");
    },
    displayNextMonth() {
      this.$emit("displayNextMonth");
    },
  },
};
</script>

<style scoped lang="scss">
.selected-day {
  background: rgba(98, 37, 130, 0.1);
}

.event-day {
  border: 2px solid var(--v-secondary-base);
}

.cursor-pointer {
  cursor: pointer;
}
</style>
