<template>
  <div v-if="!loading && dialog">
    <v-dialog
      v-model="dialog"
      max-width="860px"
      @click:outside="closeModal"
      @keydown.esc="closeModal"
    >
      <v-card class="pa-10 pa-md-12">
        <v-btn
          icon
          :ripple="false"
          absolute
          right
          top
          class="btn-background-transparent hover-close-btn"
          aria-label="Cerrar creción de evento de calendario"
          @click="closeModal"
        >
          <v-icon large color="secondary">mdi-close</v-icon>
        </v-btn>
        <div
          class="text-center font-weight-bold text-h3 text-sm-h2 mt-4 mt-md-0"
          style="word-break: break-word"
        >
          Agregar evento a calendario
        </div>
        <v-form ref="eventCreationForm" v-model="valid" lazy-validation>
          <p class="text-h4 mt-7">
            Selecciona la publicación correspondiente al evento.
          </p>
          <v-radio-group
            v-model="productType"
            row
            class="mt-6"
            @change="resetProductType"
          >
            <v-radio value="service" color="secondary" :ripple="false">
              <template v-slot:label>
                <span class="text-h4 black--text ml-1">Servicio</span>
              </template>
            </v-radio>
            <v-radio
              value="package"
              color="secondary"
              :ripple="false"
              class="ml-6"
            >
              <template v-slot:label>
                <span class="text-h4 black--text ml-1">Paquete</span>
              </template>
            </v-radio>
          </v-radio-group>
          <v-row class="mt-1">
            <v-col cols="12" sm="6" md="4">
              <v-select
                v-model="productId"
                :items="products"
                item-text="name"
                item-value="id"
                class="custom-select-input v-input--is-focused rounded-lg secondary--text"
                color="secondary"
                outlined
                dense
                hide-details="auto"
                :placeholder="
                  productType === 'service'
                    ? 'Selecciona un servicio'
                    : productType === 'package'
                    ? 'Selecciona un paquete'
                    : 'Selecciona un producto'
                "
                item-color="secondary"
                :menu-props="{
                  bottom: true,
                  closeOnContentClick: true,
                  allowOverflow: true,
                  offsetY: true,
                  rounded: 'md',
                  transition: 'slide-y-transition',
                }"
                :rules="productId !== 0 ? optionRules : []"
              />
            </v-col>
            <v-col v-if="productId === 0" cols="12" sm="6" md="4">
              <v-text-field
                v-model="productName"
                outlined
                dense
                class="rounded-lg v-input--is-focused"
                validate-on-blur
                autocomplete="null"
                maxlength="50"
                color="secondary"
                :rules="fillInput"
                :placeholder="
                  'Nombre del ' +
                  (productType === 'service'
                    ? 'servicio'
                    : productType === 'package'
                    ? 'paquete'
                    : 'producto')
                "
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-7">
            <v-col cols="12" sm="6" md="4">
              <label class="text-body-1 font-weight-bold">
                Nombre del cliente
              </label>
              <v-text-field
                v-model="clientName"
                outlined
                dense
                class="rounded-lg v-input--is-focused mt-3 mt-md-5"
                validate-on-blur
                autocomplete="null"
                maxlength="50"
                color="secondary"
                :rules="fillInput"
                placeholder="Nombre y apellido"
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <label class="text-body-1 font-weight-bold">
                Fecha del evento
              </label>
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="computedDateFormatted"
                    readonly
                    v-bind="attrs"
                    outlined
                    dense
                    class="rounded-lg v-input--is-focused mt-3 mt-md-5"
                    type="text"
                    append-icon="mdi-calendar-month-outline secondary--text"
                    placeholder="DD/MM/AAAA"
                    hide-details="auto"
                    :rules="fillInput"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  color="secondary"
                  no-title
                  required
                  prev-icon="fa-chevron-left secondary--text"
                  next-icon="fa-chevron-right secondary--text"
                  show-adjacent-months
                  locale="es-ES"
                  :min="
                    new Date(
                      Date.now() - new Date().getTimezoneOffset() * 60000
                    )
                      .toISOString()
                      .slice(0, 10)
                  "
                  @input="dateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <label class="text-body-1 font-weight-bold">
                Horario del evento
              </label>
              <v-row no-gutters class="mt-3 mt-md-5">
                <v-col>
                  <v-text-field
                    v-model="startTime"
                    v-mask="'##:##'"
                    flat
                    solo
                    dense
                    outlined
                    required
                    hide-details="auto"
                    type="text"
                    color="secondary"
                    background-color="transparent"
                    placeholder="00:00"
                    validate-on-blur
                    :rules="twentyFourHourRules"
                    class="rounded-lg v-input--is-focused"
                  />
                </v-col>
                <v-col cols="auto" class="d-flex align-center">
                  <h3 class="text-h4 mx-3">a</h3>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="endTime"
                    v-mask="'##:##'"
                    flat
                    solo
                    dense
                    outlined
                    required
                    hide-details="auto"
                    type="text"
                    color="secondary"
                    background-color="transparent"
                    placeholder="00:00"
                    validate-on-blur
                    :rules="twentyFourHourRules"
                    class="rounded-lg v-input--is-focused"
                  />
                </v-col>
                <v-col
                  v-if="isInvalidSchedule"
                  cols="12"
                  class="text-body-1 red--text mt-4"
                >
                  La hora de inicio debe ser menor que la hora de finalización.
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <div class="mt-8 mt-md-16 pt-1 text-center">
            <Button
              text="Cancelar"
              outlined
              aria-label="Cerrar creción de evento de calendario"
              :horizontal-padding="6"
              :block="$vuetify.breakpoint.xs"
              :disabled="eventCreationLoading"
              @event="closeModal()"
            />
            <Button
              text="Agendar"
              aria-label="Agendar evento en calendario"
              :block="$vuetify.breakpoint.xs"
              :disabled="eventCreationLoading"
              class="mt-3 mt-sm-0 ml-sm-5"
              @event="submit()"
            />
          </div>
        </v-form>
      </v-card>
    </v-dialog>
    <ModalLoading :dialog="eventCreationLoading" />
  </div>
</template>

<script>
import Button from "@/components/Shared/Button.vue";
import ModalLoading from "@/components/Shared/Modal/ModalLoading.vue";
import { GET_PROVIDER_PRODUCTS } from "@/graphql/queries";
import { CREATE_CALENDAR_EVENT } from "@/graphql/mutations";
import { useQuery, useMutation } from "@/graphql/index";
import { mapState } from "vuex";
import { optionRules, fillInput, twentyFourHourRules } from "@/Utils/rules";
import {
  convertYYYYMMDDToDDMMYYYY,
  convertDateWithTwentyFourHourToUnix,
} from "@/Utils/dateConverter.js";
import VueMask from "v-mask";
import Vue from "vue";

Vue.use(VueMask);
export default {
  name: "ModalEventCreation",
  components: {
    Button,
    ModalLoading,
  },
  props: {
    dialog: { type: Boolean, required: true },
    currentDate: { type: Date, required: true },
    selectedDate: { type: Date, required: true },
  },
  emits: ["closeModal", "getCalendarEvents", "displayEvents"],
  data() {
    return {
      services: [],
      packages: [],
      dateMenu: false,
      productType: "service",
      productId: "",
      productName: "",
      clientName: "",
      date: "",
      startTime: "",
      endTime: "",
      valid: false,
      isInvalidSchedule: false,
      loading: true,
      eventCreationLoading: false,
      optionRules: optionRules,
      fillInput: fillInput,
      twentyFourHourRules: twentyFourHourRules,
    };
  },
  computed: {
    ...mapState({
      provider(state) {
        return state.provider;
      },
    }),
    products() {
      let products = [{ id: 0, name: "Otro" }];
      if (this.productType === "service") {
        products = [...this.services, ...products];
      } else if (this.productType === "package") {
        products = [...this.packages, ...products];
      }
      return products;
    },
    computedDateFormatted() {
      return convertYYYYMMDDToDDMMYYYY(this.date);
    },
  },
  watch: {
    dialog: {
      async handler(dialog) {
        if (!dialog) return;
        this.setInitialDate();
      },
      immediate: true,
    },
    provider: {
      async handler(provider) {
        if (!provider || !provider.id) return;
        this.fetchProducts(provider.id);
      },
      immediate: true,
    },
  },
  methods: {
    closeModal() {
      this.dateMenu = false;
      this.$emit("closeModal");
    },
    setInitialDate() {
      if (
        (this.currentDate.getFullYear() <= this.selectedDate.getFullYear() &&
          this.currentDate.getMonth() <= this.selectedDate.getMonth() &&
          this.currentDate.getDate() <= this.selectedDate.getDate()) ||
        (this.currentDate.getFullYear() <= this.selectedDate.getFullYear() &&
          this.currentDate.getMonth() < this.selectedDate.getMonth())
      ) {
        this.date = this.selectedDate.toISOString().split("T", 1)[0];
      } else {
        this.date = "";
      }
    },
    async fetchProducts(providerId) {
      try {
        const { data, errors } = await useQuery(GET_PROVIDER_PRODUCTS, {
          providerId: parseInt(providerId),
        });

        if (errors) throw Error;

        this.services = data.provider.service;
        this.packages = data.providerPackages;
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    resetProductType() {
      this.productId = "";
    },
    isValidHour(val) {
      return /^(0?[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(val);
    },
    parseTime(timeStr) {
      const [hour, minute] = timeStr.split(":").map(Number);
      return { hour, minute };
    },
    toDate(time) {
      const date = new Date();
      date.setHours(time.hour, time.minute, 0, 0);
      return date;
    },
    validateSchedule() {
      const timeStart = this.toDate(this.parseTime(this.startTime));
      const timeEnd = this.toDate(this.parseTime(this.endTime));
      return timeStart >= timeEnd;
    },
    handleAlert({ type, message }) {
      this.$store.dispatch("handleAlert", {
        type: type,
        message: message,
      });
    },
    resetEventData() {
      this.productType = "service";
      this.productId = "";
      this.productName = "";
      this.clientName = "";
      this.date = "";
      this.startTime = "";
      this.endTime = "";
    },
    async submit() {
      this.noSchedule =
        !this.isValidHour(this.startTime) || !this.isValidHour(this.endTime);

      this.isInvalidSchedule = this.validateSchedule();

      if (
        this.$refs.eventCreationForm.validate() &&
        !this.noSchedule &&
        !this.isInvalidSchedule
      ) {
        try {
          this.eventCreationLoading = true;
          let eventObject = {
            providerId: parseInt(this.provider.id),
            clientName: this.clientName,
            startTime: convertDateWithTwentyFourHourToUnix(
              this.date,
              this.startTime
            ),
            endTime: convertDateWithTwentyFourHourToUnix(
              this.date,
              this.endTime
            ),
            isExternalEvent: true,
            name:
              this.productId === 0
                ? this.productName
                : this.productType === "service"
                ? this.services.find((s) => s.id === this.productId).name
                : this.productType === "package"
                ? this.packages.find((p) => p.id === this.productId).name
                : "",
          };

          if (this.productId !== 0) {
            if (this.productType === "service") {
              eventObject = {
                ...eventObject,
                serviceId: parseInt(this.productId),
              };
            } else if (this.productType === "package") {
              eventObject = {
                ...eventObject,
                packageId: parseInt(this.productId),
              };
            }
          }

          const { errors } = await useMutation(
            CREATE_CALENDAR_EVENT,
            eventObject
          );

          if (errors) throw Error;

          this.eventCreationLoading = false;
          this.handleAlert({
            type: "success",
            message: "Evento creado exitosamente",
          });
          this.$emit("getCalendarEvents");
          this.$emit("displayEvents", {
            day: parseInt(this.date.slice(8, 10)),
            month: parseInt(this.date.slice(5, 7)),
            year: parseInt(this.date.slice(0, 4)),
          });
          this.resetEventData();
          this.closeModal();
        } catch (error) {
          console.log(error);
          this.handleAlert({
            type: "error",
            message:
              "Hubo un error al crear evento, por favor intente de nuevo",
          });
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.btn-background-transparent::before {
  background-color: transparent !important;
}

.hover-close-btn:hover .v-icon {
  transform: scale(1.1);
}
</style>
