<template>
  <v-row no-gutters>
    <v-col
      v-for="(month, i) in months"
      :key="i"
      cols="12"
      sm="6"
      md="4"
      class="mb-15 pb-3 px-2"
      :class="[
        $vuetify.breakpoint.sm && i % 2 === 0 && 'month-divider',
        $vuetify.breakpoint.mdAndUp && (i + 1) % 3 !== 0 && 'month-divider',
      ]"
    >
      <div class="text-center text-h4 font-weight-bold mt-n1 mb-2">
        {{ monthsHeaders[i] }}
      </div>
      <v-row
        v-for="(week, j) in month"
        :key="j"
        class="flex-nowrap mt-2"
        no-gutters
      >
        <v-col
          v-for="(dayOfWeek, k) in week"
          :key="k"
          class="d-flex flex-column align-center"
        >
          <span v-if="j === 0" class="text-caption-2 mb-2">
            {{ daysOfWeekHeaders[k] }}
          </span>
          <v-sheet
            :height="
              $vuetify.breakpoint.xs ? 40 : $vuetify.breakpoint.sm ? 37 : 32
            "
            :width="
              $vuetify.breakpoint.xs ? 40 : $vuetify.breakpoint.sm ? 37 : 32
            "
            class="rounded-circle d-flex justify-center align-center"
            :class="[
              dayOfWeek.day && 'cursor-pointer',
              i === selectedMonth - 1 &&
                dayOfWeek.day === selectedDay &&
                'selected-day event-day',
              dayOfWeek.hasEvent && 'event-day',
            ]"
            @click="dayOfWeek.day && displayEvents(dayOfWeek.day, i)"
          >
            <span
              class="text-caption"
              :class="
                i === selectedMonth - 1 &&
                dayOfWeek.day === selectedDay &&
                'font-weight-bold'
              "
            >
              {{ dayOfWeek.day }}
            </span>
          </v-sheet>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "YearCalendar",
  props: {
    selectedMonth: { type: Number, required: true },
    selectedYear: { type: Number, required: true },
    selectedDay: { type: Number, required: true },
    calendarEvents: { type: Array, required: true },
  },
  emits: ["displayEvents"],
  data() {
    return {
      months: [[], [], [], [], [], [], [], [], [], [], [], []],
      monthsHeaders: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      daysOfWeekHeaders: ["DOM", "LUN", "MAR", "MIÉ", "JUE", "VIE", "SÁB"],
    };
  },
  watch: {
    selectedYear() {
      this.generateMonths();
    },
  },
  created() {
    this.generateMonths();
  },
  methods: {
    generateMonths() {
      this.months = [[], [], [], [], [], [], [], [], [], [], [], []];

      const year = this.selectedYear;

      for (let month = 1; month <= 12; month++) {
        const daysInMonth = new Date(year, month, 0).getDate();
        const firstDayOfMonth = new Date(year, month - 1, 1).getDay();
        const lastDayOfMonth = new Date(year, month - 1, daysInMonth).getDay();

        const startBlankDays = Array.from({ length: firstDayOfMonth }, () => ({
          day: "",
          hasEvent: false,
        }));

        const days = Array.from({ length: daysInMonth }, (_, i) => {
          const startDate = new Date(year, month - 1, i + 1);
          const endDate = new Date(year, month - 1, i + 2);

          const startTimestamp = Math.floor(startDate.getTime() / 1000);
          const endTimestamp = Math.floor(endDate.getTime() / 1000);

          const hasEvent = this.calendarEvents.some(
            (event) =>
              (startTimestamp >= parseInt(event.startTime) &&
                endTimestamp < parseInt(event.startTime)) ||
              (startTimestamp >= parseInt(event.endTime) &&
                endTimestamp < parseInt(event.endTime)) ||
              (startTimestamp <= parseInt(event.startTime) &&
                endTimestamp >= parseInt(event.endTime))
          );

          return {
            day: i + 1,
            hasEvent: hasEvent,
          };
        });

        const endBlankDays = Array.from({ length: 6 - lastDayOfMonth }, () => ({
          day: "",
          hasEvent: false,
        }));

        const allDays = startBlankDays.concat(days, endBlankDays);

        while (allDays.length) {
          this.months[month - 1].push(allDays.splice(0, 7));
        }
      }
    },
    displayEvents(day, month) {
      this.$emit("displayEvents", {
        day,
        month: month + 1,
        year: this.selectedYear,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.month-divider {
  border-right: 1px solid var(--v-secondary-base);
}

.selected-day {
  background: rgba(98, 37, 130, 0.1);
}

.event-day {
  border: 2px solid var(--v-secondary-base);
}

.cursor-pointer {
  cursor: pointer;
}
</style>
