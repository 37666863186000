<template>
  <div>
    <div class="purple-gradient fill-height">
      <Navbar />
    </div>
    <div class="body-container">
      <v-container v-if="!loading" class="body-container pb-9 px-6 px-md-auto">
        <h1 class="text-h1 font-weight-bold my-16 pt-md-3">Calendario</h1>
        <div class="d-flex flex-wrap">
          <Button
            text="Mes"
            outlined
            pill
            :height="44"
            :active="displayMode === 'month'"
            class="mr-2 mb-2"
            aria-label="Mostrar calendario por mes"
            @event="setDisplayMode('month')"
          />
          <Button
            text="Año"
            outlined
            pill
            :height="44"
            :active="displayMode === 'year'"
            class="mr-2 mb-2"
            aria-label="Mostrar calendario por año"
            @event="setDisplayMode('year')"
          />
          <v-select
            v-if="displayMode === 'month'"
            v-model="selectedMonth"
            :items="monthsToSelect"
            placeholder="Meses"
            item-text="name"
            item-value="num"
            class="custom-select-input v-input--is-focused rounded-pill text-h4 secondary--text mr-2 mb-2"
            color="secondary"
            outlined
            dense
            hide-details
            height="44"
            style="max-width: 180px"
            item-color="secondary"
            :menu-props="{
              bottom: true,
              closeOnContentClick: true,
              allowOverflow: true,
              offsetY: true,
              rounded: 'md',
              transition: 'slide-y-transition',
            }"
            @change="resetDay()"
          />
          <v-select
            v-model="selectedYear"
            :items="yearsToSelect"
            placeholder="Años"
            :value="selectedYear"
            class="custom-select-input v-input--is-focused rounded-pill text-h4 secondary--text mb-2"
            color="secondary"
            outlined
            dense
            hide-details
            height="44"
            style="max-width: 180px"
            item-color="secondary"
            :menu-props="{
              bottom: true,
              closeOnContentClick: true,
              allowOverflow: true,
              offsetY: true,
              rounded: 'md',
              transition: 'slide-y-transition',
            }"
            @change="
              resetDay();
              resetMonth();
            "
          />
        </div>
        <v-row class="d-flex flex-column flex-lg-row mt-2">
          <v-col cols="12" lg="8">
            <MonthCalendar
              v-if="displayMode === 'month'"
              :selected-month="selectedMonth"
              :selected-year="selectedYear"
              :selected-day="selectedDay"
              :calendar-events="calendarEvents"
              class="mt-5"
              @displayEvents="displayEvents($event)"
              @displayPreviousMonth="displayPreviousMonth()"
              @displayNextMonth="displayNextMonth()"
            />
            <YearCalendar
              v-else-if="displayMode === 'year'"
              :selected-month="selectedMonth"
              :selected-year="selectedYear"
              :selected-day="selectedDay"
              :calendar-events="calendarEvents"
              class="mt-8"
              @displayEvents="displayEvents($event)"
            />
          </v-col>
          <v-col cols="12" lg="4" class="pl-lg-10">
            <h1
              v-if="selectedDay > 0"
              ref="events"
              class="text-h3 text-md-h2 font-weight-bold mt-10 mt-lg-0"
            >
              {{ selectedDay }}
              de
              {{ monthsToSelect[selectedMonth - 1].name }}
              {{ selectedYear }}
            </h1>

            <h3 class="text-h4 mt-2 mb-6">
              {{
                dayEvents.length > 0
                  ? dayEvents.length +
                    (dayEvents.length === 1
                      ? " evento encontrado"
                      : " eventos encontrados")
                  : "Sin eventos reservados"
              }}
            </h3>
            <transition-group
              name="staggered-fade"
              @beforeEnter="eventCardAnimationBeforeEnter"
              @enter="eventCardAnimationEnter"
              @leave="eventCardAnimationLeave"
            >
              <CalendarEventCard
                v-for="(dayEvent, index) in dayEvents"
                :key="dayEvent.id"
                :data-index="index"
                :event-name="dayEvent.name"
                :event-client="dayEvent.clientName"
                :event-start-time="dayEvent.startTime"
                :event-end-time="dayEvent.endTime"
                :is-external-event="dayEvent.isExternalEvent"
                :product-type="
                  dayEvent.serviceId
                    ? 'Servicio'
                    : dayEvent.packageId || dayEvent.packageReservationId
                    ? 'Paquete'
                    : ''
                "
                :reservation-id="dayEvent.packageReservationId"
                class="mb-6"
              />
            </transition-group>
            <!-- <div v-if="dayEvents.length === 0" class="mt-3">
              <v-radio-group v-model="isDayFullyAvailable" row class="mt-0">
                <v-radio :value="true" color="secondary" :ripple="false">
                  <template v-slot:label>
                    <span class="text-h4 black--text ml-1">Disponible</span>
                  </template>
                </v-radio>
                <v-radio
                  :value="false"
                  color="secondary"
                  :ripple="false"
                  class="ml-6"
                >
                  <template v-slot:label>
                    <span class="text-h4 black--text ml-1">Bloqueado</span>
                  </template>
                </v-radio>
              </v-radio-group>
            </div> -->
            <Button
              text="+Agregar evento"
              :block="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.md"
              class="mt-8"
              aria-label="Abrir ventana para agregar un evento"
              @event="handleEventCreationModal()"
            />
          </v-col>
        </v-row>
      </v-container>
      <v-container v-else>
        <InlineLoading />
      </v-container>
    </div>
    <ModalEventCreation
      v-if="!loading"
      :dialog="eventCreationModal"
      :current-date="currentDate"
      :selected-date="selectedDate"
      @getCalendarEvents="getCalendarEvents"
      @displayEvents="displayEvents($event)"
      @closeModal="handleEventCreationModal"
    />
    <Footer class="mt-16" />
  </div>
</template>

<script>
import Navbar from "@/components/Provider/Shared/Navbar.vue";
import CalendarEventCard from "@/components/Shared/Calendar/CalendarEventCard.vue";
import MonthCalendar from "@/components/Shared/Calendar/MonthCalendar.vue";
import YearCalendar from "@/components/Shared/Calendar/YearCalendar.vue";
import Button from "@/components/Shared/Button.vue";
import ModalEventCreation from "@/components/Provider/Calendar/ModalEventCreation.vue";
import InlineLoading from "@/components/Shared/InlineLoading.vue";
import Footer from "@/components/Shared/Footer.vue";
import { mapState } from "vuex";
import { useQuery } from "@/graphql/index";
import { GET_PROVIDER_CALENDAR_EVENTS } from "@/graphql/queries";

export default {
  name: "Calendar",
  components: {
    Navbar,
    CalendarEventCard,
    MonthCalendar,
    YearCalendar,
    Button,
    ModalEventCreation,
    InlineLoading,
    Footer,
  },
  data() {
    return {
      loading: true,
      displayMode: "month",
      currentDate: new Date(),
      // isDayFullyAvailable: true,
      selectedDay: 0,
      selectedYear: undefined,
      selectedMonth: 1,
      monthsToSelect: [
        { name: "Enero", num: 1 },
        { name: "Febrero", num: 2 },
        { name: "Marzo", num: 3 },
        { name: "Abril", num: 4 },
        { name: "Mayo", num: 5 },
        { name: "Junio", num: 6 },
        { name: "Julio", num: 7 },
        { name: "Agosto", num: 8 },
        { name: "Septiembre", num: 9 },
        { name: "Octubre", num: 10 },
        { name: "Noviembre", num: 11 },
        { name: "Diciembre", num: 12 },
      ],
      yearsToSelect: [],
      calendarEvents: [],
      eventCreationModal: false,
    };
  },
  computed: {
    ...mapState({
      provider(state) {
        return state.provider;
      },
    }),
    dayEvents() {
      if (!this.calendarEvents) return [];
      const selectedStartDate = new Date(
        this.selectedYear,
        this.selectedMonth - 1,
        this.selectedDay
      );
      const selectedEndDate = new Date(
        this.selectedYear,
        this.selectedMonth - 1,
        this.selectedDay + 1
      );

      const selectedStartTimestamp = Math.floor(
        selectedStartDate.getTime() / 1000
      );
      const selectedEndTimestamp = Math.floor(selectedEndDate.getTime() / 1000);

      const filteredSelectedDayEvents = this.calendarEvents.filter((event) => {
        return (
          (parseInt(event.startTime) >= selectedStartTimestamp &&
            parseInt(event.startTime) < selectedEndTimestamp) ||
          (parseInt(event.endTime) >= selectedStartTimestamp &&
            parseInt(event.endTime) < selectedEndTimestamp) ||
          (parseInt(event.startTime) <= selectedStartTimestamp &&
            parseInt(event.endTime) >= selectedEndTimestamp)
        );
      });

      return filteredSelectedDayEvents;
    },
    selectedDate() {
      return new Date(
        this.selectedYear,
        this.selectedMonth - 1,
        this.selectedDay
      );
    },
  },
  watch: {
    provider: {
      async handler(provider) {
        if (!provider || !provider.id) return;
        this.getCalendarEvents();
      },
      immediate: true,
    },
  },
  created() {
    this.setInitialDate();
    this.setYearsToSelect();
  },
  methods: {
    setInitialDate() {
      this.selectedYear = this.currentDate.getFullYear();
      this.selectedMonth = this.currentDate.getMonth() + 1;
      this.selectedDay = this.currentDate.getDate();
    },
    setYearsToSelect() {
      const currentYear = this.currentDate.getFullYear();
      for (let i = 0; i <= 3; i++) {
        this.yearsToSelect.push(currentYear - 1 + i);
      }
    },
    async getCalendarEvents() {
      const { data, errors } = await useQuery(GET_PROVIDER_CALENDAR_EVENTS, {
        providerId: parseInt(this.provider.id),
      });

      if (errors) throw errors;

      this.calendarEvents = data.providerCalendarEvents;
      this.loading = false;
    },
    setDisplayMode(mode) {
      this.setInitialDate();
      this.displayMode = mode;
    },
    resetDay() {
      this.selectedDay = 1;
    },
    resetMonth() {
      this.selectedMonth = 1;
    },
    displayPreviousMonth() {
      if (this.selectedMonth === 1) {
        this.selectedMonth = 12;
        this.selectedYear = this.selectedYear - 1;
      } else {
        this.selectedMonth = this.selectedMonth - 1;
      }
      this.resetDay();
    },
    displayNextMonth() {
      if (this.selectedMonth === 12) {
        this.resetMonth();
        this.selectedYear = this.selectedYear + 1;
      } else {
        this.selectedMonth = this.selectedMonth + 1;
      }
      this.resetDay();
    },
    scrollToEvents() {
      const element = this.$refs["events"];
      const rect = element.getBoundingClientRect();

      if (
        rect.top < 0 ||
        rect.left < 0 ||
        rect.bottom >
          (window.innerHeight || document.documentElement.clientHeight) ||
        rect.right > (window.innerWidth || document.documentElement.clientWidth)
      ) {
        window.scrollTo({ top: element.offsetTop - 25, behavior: "smooth" });
      }
    },
    displayEvents(date) {
      this.selectedDay = date.day;
      this.selectedMonth = date.month;
      this.selectedYear = date.year;

      this.scrollToEvents();
    },
    handleEventCreationModal() {
      this.eventCreationModal = !this.eventCreationModal;
    },
    eventCardAnimationBeforeEnter: function (el) {
      el.style.opacity = 0;
    },
    eventCardAnimationEnter: function (el, done) {
      const delay = el.dataset.index * 100;
      setTimeout(function () {
        el.style.transition = "opacity 0.5s";
        el.style.opacity = 1;
        done();
      }, delay);
    },
    eventCardAnimationLeave: function (_el, done) {
      done();
    },
  },
};
</script>

<style scoped>
.purple-gradient {
  background-color: rgba(98, 37, 130, 255);
}

::v-deep .custom-select-input input::placeholder {
  color: black !important;
  padding: 0 6px;
}

::v-deep .custom-select-input .v-input__append-inner {
  padding-top: 4px;
}
</style>
